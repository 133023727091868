import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';

import { DialogButton, ComponentController } from 'components/common';
import { LineOfBusinessFields } from '../Helpers';

const obligorMaxLength = 130;

export const ObligorPopup = ({
  popupState,
  setPopupState,
  setFormState,
  setValidationValue
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: { obligor: popupState.newValue }
  });

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]);

  const onSubmit = useCallback(async popupData => {
    handleClose();

    let obligor = popupData.obligor;

    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, obligor }
    }));

    setValidationValue("obligor", obligor, { shouldValidate: true });
  }, [setFormState, setValidationValue, handleClose]);

  const getRequiredValidationError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{LineOfBusinessFields[name]}" is required</span>;
  }

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{LineOfBusinessFields[name]}" maximum length is {obligorMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="obligor-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="obligor-dialog-title">Add a new Obligor to Sequel Eclipse</DialogTitle>
        <DialogContent>
          <ComponentController
            name="obligor"
            control={control}
            required
            rules={{ maxLength: obligorMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={LineOfBusinessFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};