import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import { S4000Entity, ReassuredPlacingBases, CoverHolderPlacingBases } from 'components/SubmissionWizard/Helpers';
import { PlacingBases, RenewalLinkReference, CustomLinkType } from "./Constants";

const day = 86400000; //one day in the milliseconds.
const entityFieldName = "entityCode";

export const dualStampEntity = "Dual Stamp S4000/HIDAC";
export const singlePolicyStructure = "Single";
export const declarationPolicyStructure = "";

export const getInceptionDate = expiryDate => {
  return new Date((new Date(expiryDate).getTime() + day));
}

// todo: extract to common helpers
export const getFormattedDate = date => {
  if (!date) {
    return null;
  }
  const parsedDate = new Date(date);

  let utils = new DateFnsUtils();
  return utils.format(parsedDate, "yyyy-MM-dd");
}

export const getUkFormattedDateString = date => {
  if (!date) {
    return null;
  }

  let utils = new DateFnsUtils();
  return utils.format(date, "dd/MM/yyyy");
}

export const toLocalDateTime = date => {
  if (!date) {
    return null;
  }

  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export const setZeroTime = dateString => {
  if (!dateString) {
    return null;
  }

  return dateString.replace(/T[0-9:.Z]+$/, "T00:00:00");
}

export const isDualStampEntity = entity => {
  return entity === dualStampEntity;
};

export const isDeclaration = placingBasisCode => {
  return placingBasisCode === PlacingBases.Declaration;
};

export const isReassured = placingBasisCode => {
  return ReassuredPlacingBases.includes(placingBasisCode);
};

export const isCoverHolder = placingBasisCode => {
  return CoverHolderPlacingBases.includes(placingBasisCode);
};

export const isEntityField = fieldName => {
  return fieldName === entityFieldName;
};

export const resolveS4000EntityName = name => {
  return name === S4000Entity.Value ? S4000Entity.DisplayValue : name;
}

export const isMajorClassRequiredModelling = (majorClass, entity, dropdownsData) => {
  return dropdownsData.majorClasses.find(d => d.name === majorClass)?.modellingRequired &&
    !dropdownsData.entities[entity]?.isRequiredModellingPriorityDisabled;
};

export const validatePlacingBasis = (placingBasis, documentType, documentTypesList, placingBasisList) =>
{
  const placingBasisCode = placingBasisList?.find(x => x.name === placingBasis)?.code;
  return !!documentTypesList?.find(x => x.name === documentType)?.allowedPlacingBases?.includes(placingBasisCode);
};
  
export const convertToArray = (dataString, separator = ",") =>
  dataString?.split(separator).map(x => x.trim()) ?? [];

export const createMajorClassStructure = (assured, modellingMajorClasses) => {
  if (!assured) {
    return null;
  }

  return {
    majorClass: assured.majorClassDescription,
    isModellingPriority: Object.values(modellingMajorClasses).includes(assured.majorClassDescription),
    majorClassCodes: [assured.majorClassCode]
  };
}

export const isPolicyRenewedWithCustomLink = (policy) =>{
   const isPolicyRenewed = !!policy?.policyLinks?.find(
    pl => pl.linkType?.toLowerCase() === CustomLinkType &&
    pl.linkRef?.toLowerCase() === RenewalLinkReference && 
    pl.referencedPolicies[0]?.policyId > policy?.detail?.id);
    return isPolicyRenewed;
}

export const isFieldUpdateRequired = (organisationType, placingBasisCode) => {
  return organisationType === "obligor" || (organisationType === "assured" && (ReassuredPlacingBases.includes(placingBasisCode) || CoverHolderPlacingBases.includes(placingBasisCode)));    
}