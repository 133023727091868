import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useStarAiContext } from 'star-ai'
import { FormControl, Grid, TextField } from '@material-ui/core';

import { Datasource } from 'Api';
import { validateDate } from 'Helpers';

import {
  BannerType, CheckboxElement, DatePickerElement, Dropdown,
  FormControlWrapper, PickerComponent, SwitchComponent, ComponentController, InformationMessage
} from 'components/common';
import { ConditionCode, DefaultConditionName, MasterPolicyReferenceControl, DefaultMasterPolicyReferenceControlName, SecondMasterPolicyReferenceControl, DefaultSecondMasterPolicyReferenceControlName } from './common';
import {
  GeneralDetailsFields, PlacingBases, ReassuredPlacingBases,
  isDualStampEntity, S4000Entity, validatePlacingBasis, DocumentTypes, setZeroTime, toLocalDateTime, singlePolicyStructure, isDeclaration,
  isPolicyRenewedWithCustomLink,
  TypesOfReassured,
  OrganisationDataSources,
  CoverHolderPlacingBases,
  OrganisationTypes,
  getFormattedDate,
  SingleEntityFieldNames,
  DualEntityFieldNames
} from 'components/SubmissionWizard/Helpers';
import { getAtaccamaCompanyByOrbisId, getAtaccamaOrganisation, getPolicy } from 'components/SubmissionWizard/SubmissionWizard.api';
import { AssuredPopup, ReassuredPopup, BrokerContactPopup, IndividualAssuredPopup, CompanyPopup, AssuredEclipsePopup } from 'components/SubmissionWizard/Popups';
import { renderAssuredLookupView, renderAssuredLookupHeader } from 'components/SubmissionWizard/Views';

import '../GeneralDetails.css';
import { SanctionWarning } from './common/SanctionWarning';

const SubmissionSection = ({
  fields,
  initialFormState,
  renewalState,
  initialRenewalState,
  dropdownsData,
  businessEntity,
  control,
  errors,
  forceClearDatasourceState,
  setFormState,
  setRenewalState,
  setValidationValue,
  trigger,
  setLoading,
  setBannerState,
  setBusinessEntity,
  setGeneralDetailsState,
  validationRef,
  resetValidationState,
  resetFieldValidationState,
  setLineOfBusinessState,
  setForceClearDatasourceState,
  onOrganisationsDataLoad
}) => {

  // star ai
  const ai = useStarAiContext();

  const maxAddressLength = 250;
  const maxPostCodeLength = 15;

  const [abortController, setAbortController] = useState(new AbortController());
  const newAssuredValue = useRef(null);
  const [sanctionsDialogConfig, setSanctionsDialogConfig] = useState(null);
  const [sanctionWarningDialogState, setSanctionWarningDialogState] = useState(false);

  useEffect(() => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields }
    }));
  }, [setFormState]);

  useEffect(() => {
    if (fields.assured?.assured) {
      trigger('assured');
    }
  }, [fields.inceptionDate]);

  useEffect(() => {
    trigger('inceptionDate');
  }, [fields.yoa, fields.inceptionDate]);

  useEffect(() => {
    let checkIfPolicyRenewed = async () => {
      if (fields.policyId && renewalState.fields.length === 0 && renewalState.dropdownsData.length === 0) {
        setLoading(true);
        let response = await getPolicy(fields.policyId);
        setLoading(false);

        if (response.success) {
          let policy = response.data;
          let isPolicyRenewed = !!policy?.detail?.renewedToId || isPolicyRenewedWithCustomLink(policy);

          setFormState(previous => ({
            ...previous,
            fields: {
              ...previous.fields,
              isExpiringPolicyRenewed: isPolicyRenewed,
              expiringProgrammeReference: policy?.detail.programRef
            }
          }));

          setValidationValue("expiringPolicyReferenceNumber", fields.expiringPolicyReferenceNumber, { shouldValidate: true });
        } else {
          setBannerState({
            show: true,
            type: BannerType.error,
            message: response.errorMessage
          });
        }
      }
    }

    checkIfPolicyRenewed();
  }, [
    fields.policyId, fields.expiringPolicyReferenceNumber, renewalState,
    setLoading, setBannerState, setFormState, setValidationValue
  ]);

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value === "" && name === "inceptionDate" ? null : value }
    }));
  }, [setFormState]);

  const getOrbisDataPacket = (fields, property, item, updateKycDetails) => {
    if (item?.source === OrganisationDataSources.Orbis && updateKycDetails) {
      return { ...fields.kycDataPacket, [property]: { ...item } };
    }

    if (!item || !item[property] || item?.source !== OrganisationDataSources.Orbis) {
      return { ...fields.kycDataPacket, [property]: null };
    }

    return fields.kycDataPacket;
  }

  const onRenewedPolicyChecked = useCallback(checked => {
    resetFieldValidationState("policyReferenceNumber");
    resetFieldValidationState("expiringProgrammeReference");

    setFormState(previous => {
      let newDropdownsDataState = {
        ...previous.dropdownsData,
        years: [],
        eclipseEntities: []
      };

      let newFieldsState = {
        ...initialFormState.fields,
        critical: previous.fields.critical,
        policyEntryInformation: previous.fields.policyEntryInformation,
        documentType: previous.fields.documentType,
        renewedPolicy: checked
      };

      resetValidationState({ ...newFieldsState });
      return { ...previous, fields: { ...newFieldsState }, dropdownsData: { ...newDropdownsDataState } };
    });

    setRenewalState(previous => ({
      ...previous,
      showUserDialogWarning: false,
      fields: [],
      dropdownsData: [],
      originalRenewals: []
    }));

  }, [initialFormState, setFormState, setRenewalState, resetValidationState, resetFieldValidationState]);

  const onUnderwriterChange = useCallback(async underwriterData => { 
    let underwriter = underwriterData ?
      {
        underwriter: underwriterData.underwriter,
        underwriterAbbreviation: underwriterData.underwriterAbbreviation
      } : {};
    let yoaList = underwriterData ? underwriterData.yoaList : [];
    let eclipseEntities = underwriterData ? underwriterData.entitiesList : [];

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        underwriter: underwriter ?? '',
        yoa: '',
        businessEntity: '',
        broker: null,
        brokerContact: null,
        assured: null,
        masterPolicyReferenceNumber: '',
        masterPolicyId: null,
        masterAssured: '',
        masterReassured: '',
        secondMasterAssured: '',
        secondMasterReassured: '',
        _masterAssuredOrMasterReassured: '',
        _secondMasterAssuredOrMasterReassured: ''
      },
      dropdownsData: {
        ...previous.dropdownsData,
        years: yoaList,
        eclipseEntities: eclipseEntities
      }
    }));

    setValidationValue("yoa", "");
    setValidationValue("businessEntity", "");
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setForceClearDatasourceState({ "assured": true });
  }, [setFormState, setValidationValue, setForceClearDatasourceState]);

  const onYOAChange = useCallback(yoa => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        yoa: yoa ?? '',
        businessEntity: '',
        broker: null,
        brokerContact: null,
        masterPolicyReferenceNumber: '',
        masterPolicyId: null,
        masterAssured: '',
        masterReassured: '',
        secondMasterAssured: '',
        secondMasterReassured: '',
        _masterAssuredOrMasterReassured: '',
        _secondMasterAssuredOrMasterReassured: ''
      }
    }));

    setValidationValue("businessEntity", "");
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setForceClearDatasourceState({ "assured": true, "_masterAssuredOrMasterReassured": true, "_secondMasterAssuredOrMasterReassured": true });
  }, [setFormState, setValidationValue, setForceClearDatasourceState]);

  const onEntityChange = useCallback(event => {
    let businessEntity = dropdownsData.eclipseEntities.find(x => x.entityDisplayName === event.target?.value);

    setBusinessEntity(businessEntity);

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        businessEntity: businessEntity.entityCode,
        isDualStamp: businessEntity.isDualStampEntity,
        broker: null,
        brokerContact: null,
        masterPolicyReferenceNumber: '',
        masterPolicyId: null,
        masterAssured: '',
        masterReassured: '',
        _masterAssuredOrMasterReassured: '',
        _secondMasterAssuredOrMasterReassured: '',
        secondMasterAssured: '',
        secondMasterReassured: '',
        secondMasterPolicyReferenceNumber: '',
        secondMasterPolicyId: null,
        assured: null,
        secondPlacingBasis: businessEntity.isDualStampEntity ? previous.fields.placingBasis : null,
        secondPlacingBasisCode: businessEntity.isDualStampEntity ? previous.fields.placingBasisCode : null,
        secondReassured: businessEntity.isDualStampEntity ? previous.fields.secondReassured : null,
        kycDataPacket: null,
        hamiltonId: null,
        reassured: null,
        coverHolder: null
      }
    }));

    setValidationValue("broker", null);
    setValidationValue("brokerContact", null);
    setValidationValue("assured", null);
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setValidationValue("_secondMasterAssuredOrMasterReassured", "");
    setForceClearDatasourceState({ "assured": true, "_masterAssuredOrMasterReassured": true, "_secondMasterAssuredOrMasterReassured": true, "broker": true });
    abortController.abort();
  }, [abortController, dropdownsData.eclipseEntities, setBusinessEntity, setFormState, setValidationValue, setForceClearDatasourceState]);

  const clearLineOfBusinessState = (placingBasis) => {
    const initialLineOfBusinessState = {
      fields: {
        policyStructure: '',
        totalNumberOfPolicyReferences: 1,
        periodBasis: '',
        periodBasisCode: '',
        obligor: '',
      }
    };

    setLineOfBusinessState(previous => ({
      ...previous,
      fields: {
        ...initialLineOfBusinessState.fields,
        policyStructure: previous.fields.policyStructure === '' ? (isDeclaration(placingBasis) ? previous.fields.policyStructure : singlePolicyStructure) : (isDeclaration(placingBasis) ? '' : previous.fields.policyStructure),
      },
      policies: []
    }));
  };

  const onPlacingBasisChange = useCallback(event => {
    resetFieldValidationState("assured");

    let placingBasisName = event.target?.value;
    let placingBasis = dropdownsData.placingBases.find(p => p.name === placingBasisName);
    let contractBasis = placingBasis?.contractBasis;
    let placingBasisCode = placingBasis?.code;
    let prevPlacingBasisCode = fields.placingBasisCode;

    if (prevPlacingBasisCode === PlacingBases.Declaration || placingBasis?.code === PlacingBases.Declaration) {
      clearLineOfBusinessState(placingBasisCode);
    }

    setGeneralDetailsState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        placingBasis: placingBasisName,
        placingBasisCode: placingBasisCode
      }
    }));

    setFormState(previous => {
      let broker = previous.fields.broker;
      let brokerContact = previous.fields.brokerContact;
      let assured = null;
      let reassured = null;

      if (placingBasisCode === PlacingBases.Declaration) {
        broker = null;
        brokerContact = null;
      }

      return {
        ...previous,
        fields: {
          ...previous.fields,
          placingBasis: placingBasisName,
          placingBasisCode: placingBasisCode,
          secondPlacingBasis: previous.fields.isDualStamp ? placingBasisName : null,
          secondPlacingBasisCode: previous.fields.isDualStamp ? placingBasisCode : null,
          secondReassured: previous.fields.isDualStamp ? reassured : null,
          broker,
          brokerContact,
          assured,
          reassured,
          decalarationType: null,
          contractBasis,
          masterPolicyReferenceNumber: '',
          masterPolicyId: null,
          masterAssured: '',
          masterReassured: '',
          _masterAssuredOrMasterReassured: '',
          _secondMasterAssuredOrMasterReassured: '',
          secondMasterAssured: '',
          secondMasterReassured: '',
          secondMasterPolicyReferenceNumber: '',
          secondMasterPolicyId: null,
          coverHolder: null,
          umr: '',
          kycDataPacket: null,
          hamiltonId: null,
          typeOfReassured: '',
          address: '',
          postCode: ''
        }
      };
    });

    if (placingBasisCode === PlacingBases.Declaration) {
      setValidationValue("broker", null);
      setValidationValue("brokerContact", null);
      resetFieldValidationState("decalartionType");
      setForceClearDatasourceState({ "broker": true, "brokerContact": true });
    }

    if ([PlacingBases.Binder, PlacingBases.BinderNonProportionalRI, PlacingBases.BinderProportionalRI, PlacingBases.MasterBinder].includes(placingBasisCode)) {
      setValidationValue("coverHolder", "");
      setForceClearDatasourceState({ "coverHolder": true });
    }

    if (ReassuredPlacingBases.includes(placingBasisCode)) {
      setValidationValue("reassured", "");
      setForceClearDatasourceState({ "reassured": true });
    }

    setValidationValue("assured", null);
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setValidationValue("_secondMasterAssuredOrMasterReassured", "");
    setForceClearDatasourceState({ "assured": true, "_masterAssuredOrMasterReassured": true, "_secondMasterAssuredOrMasterReassured": true });
  }, [dropdownsData, fields, setFormState, resetFieldValidationState, setValidationValue, setForceClearDatasourceState, setGeneralDetailsState]);

  const onBrokerChange = useCallback(broker => {
    setValidationValue("brokerContact", null);
    setForceClearDatasourceState({ "brokerContact": true });
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        broker: broker ?? '',
        brokerContact: null
      }
    }));
  }, [setFormState, setValidationValue, setForceClearDatasourceState]);

  const onOperatingTerritoryChange = useCallback(value => {
    let operatingTerritory = value?.operatingTerritory;
    let territoryId = value?.id;

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        operatingTerritory,
        territoryId
      }
    }));
  }, [setFormState]);

  const onFirmOrderStageChange = useCallback(async checked => {
    setValidationValue("updatedTypeOfReassured", null);
    setValidationValue("updatedCountryName", null);
    setValidationValue("updatedAssured", null);

    setForceClearDatasourceState({
      "updatedAssured": true,
      "updatedCountryName": true
    });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        reassuredChangedAtFirmOrder: checked,
        typeOfReassured: '',
        dateOfBirth: null,
        address: '',
        countryName: '',
        countryCode: '',
        updatedTypeOfReassured: '',
        updatedAssured: null,
        updatedDateOfBirth: null,
        updatedAddress: '',
        updatedPostCode: '',
        updatedCountryName: '',
        updatedCountryCode: ''
      }
    }));

    if (checked) {
      setLoading(true);
      let response = await getAtaccamaOrganisation(fields.policyId);
      setLoading(false);

      if (response.success) {
        let typeOfReassured = response.data?.orgType;
        let countryName = !response.data?.entityCountryOfResidencyName ? response.data?.countryName : response.data?.entityCountryOfResidencyName;
        let countryCode = !response.data?.entityCountryOfResidencyCode ? response.data?.country : response.data?.entityCountryOfResidencyCode;
        let address = response.data?.insuredAddress;
        let postCode = response.data?.insuredPostCode;
        let dateOfBirth = response.data?.insuredDOB ? new Date(response.data?.insuredDOB) : null;
        let givenName = response.data?.givenName;
        let familyName = response.data?.familyName;

        setFormState(previous => ({
          ...previous,
          fields: {
            ...previous.fields,
            typeOfReassured,
            countryName,
            countryCode,
            dateOfBirth,
            address,
            givenName,
            familyName,
            updatedTypeOfReassured: typeOfReassured,
            updatedCountryName: countryName,
            updatedCountryCode: countryCode,
            updatedDateOfBirth: dateOfBirth,
            updatedAddress: address,
            updatedPostCode: postCode,
            updatedGivenName: givenName,
            updatedFamilyName: familyName
          }
        }));

        setValidationValue("updatedTypeOfReassured", typeOfReassured);
        setValidationValue("updatedCountryName", countryName);
        setValidationValue("updatedDateOfBirth", dateOfBirth);
        setValidationValue("updatedAddress", address);
        setValidationValue("updatedPostCode", postCode);
      } else {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: response.errorMessage
        });
      }
    }

    abortController.abort();
  }, [abortController, setFormState, setBannerState, setLoading, setValidationValue, setForceClearDatasourceState, fields.policyId]);

  const onTypeOfReassuredChange = useCallback(event => {
    let typeOfReassured = event.target?.value;

    resetFieldValidationState("_masterAssuredOrMasterReassured");
    resetFieldValidationState("_secondMasterAssuredOrMasterReassured");
    setValidationValue("assured", null);
    setValidationValue("secondAssured", null);
    setValidationValue("reassured", "");
    setValidationValue("coverHolder", "");
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setValidationValue("_secondMasterAssuredOrMasterReassured", "");
    setValidationValue("companyForSanctionsScreening", null);

    setForceClearDatasourceState({
      "assured": true,
      "reassured": true,
      "coverHolder": true,
      "obligor": true,
      "_masterAssuredOrMasterReassured": true,
      "_secondMasterAssuredOrMasterReassured": true,
      "countryName": true,
      "countryForSanctionsScreening": true,
      "companyForSanctionsScreening": true
    });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        typeOfReassured,
        assured: null,
        reassured: '',
        policyId: null,
        policyReferenceNumber: '',
        secondAssured: null,
        secondReassured: '',
        secondPolicyId: null,
        secondPolicyReferenceNumber: '',
        masterPolicyReferenceNumber: '',
        masterPolicyId: null,
        masterAssured: '',
        masterReassured: '',
        _masterAssuredOrMasterReassured: '',
        _secondMasterAssuredOrMasterReassured: '',
        secondMasterAssured: '',
        secondMasterReassured: '',
        secondMasterPolicyReferenceNumber: '',
        secondMasterPolicyId: null,
        coverHolder: '',
        dateOfBirth: null,
        countryName: '',
        countryCode: '',
        countryForSanctionsScreening: '',
        countryCodeForSanctionsScreening: '',
        companyForSanctionsScreening: null,
        address: '',
        postCode: ''
      }
    }));

    setLineOfBusinessState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        obligor: ''
      },
      policies: previous.policies.map(p => ({ ...p, declarationAssured: '', declarationAssuredId: null }))
    }));

    abortController.abort();
  }, [abortController, setFormState, setValidationValue, resetFieldValidationState, setForceClearDatasourceState, setLineOfBusinessState]);

  const onUpdatedTypeOfReassuredChange = useCallback(event => {
    let updatedTypeOfReassured = event.target?.value;

    setValidationValue("updatedAssured", null);
    setValidationValue("updatedCountryName", null);

    setForceClearDatasourceState({
      "updatedAssured": true,
      "updatedCountryName": true
    });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        updatedTypeOfReassured,
        updatedAssured: null,
        updatedDateOfBirth: null,
        updatedAddress: '',
        updatedPostCode: '',
        updatedCountryName: '',
        updatedCountryCode: ''
      }
    }));

    abortController.abort();
  }, [abortController, setFormState, setValidationValue, setForceClearDatasourceState]);

  const onRenewalTypeOfReassuredChange = useCallback(event => {
    let typeOfReassured = event.target?.value;
  
    setValidationValue("renewalAssured", null);
    setValidationValue("renewalReassured", null);
    setValidationValue("renewalCoverHolder", null);
    setValidationValue("renewalCountryName", null);
  
    setForceClearDatasourceState({
      "renewalAssured": true,
      "renewalReassured": true,
      "renewalCoverHolder": true,
      "renewalCountryName": true
    });
  
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        typeOfReassured,
        renewalTypeOfReassured: typeOfReassured,
        renewalAssured: null,
        renewalReassured: null,
        renewalCoverHolder: null,
        renewalDateOfBirth: null,
        renewalAddress: '',
        renewalCountryName: '',
        renewalCountryCode: '',
        renewalPostCode: ''
      }
    }));
  
    abortController.abort();
  }, [abortController, setFormState, setValidationValue, setForceClearDatasourceState]);  

  const handleSanctionCheck = useCallback((passfortSanctionsFlag, changeOrgDetails) => {
    const isSanctioned = passfortSanctionsFlag?.toLowerCase() === "true";

    if (isSanctioned) {
      setSanctionWarningDialogState(true);
    } else {
      changeOrgDetails();
    }
  }, [setSanctionWarningDialogState]);

  const checkForSanctionFlag = useCallback(async (org, changeOrgDetails) => {
    if (org?.source === OrganisationDataSources.Orbis) {
      setLoading(true);
      let response = await getAtaccamaCompanyByOrbisId(org.orbisId);
      setLoading(false);

      if (response.success) {
        let assuredData = response.data;
        handleSanctionCheck(assuredData.passfortSanctionsFlag, changeOrgDetails);
      }
    }
    else if (org?.source === OrganisationDataSources.Ataccama) {
      handleSanctionCheck(org.passfortSanctionsFlag, changeOrgDetails);
    }
    else {
      changeOrgDetails();
    }
  }, [setLoading, getAtaccamaCompanyByOrbisId, handleSanctionCheck]);

  const changeAssuredDetails = useCallback(() => {
    let assured = newAssuredValue.current;
    const selectedAssured = {
      assured: assured?.assured ?? '',
      assuredId: assured?.assuredId,
      effectiveFromDate: setZeroTime(assured?.effectiveFromDate),
      effectiveToDate: setZeroTime(assured?.effectiveToDate)
    };

    setFormState(previous => {
      var updateAssuredDetails = !(ReassuredPlacingBases.includes(previous.fields.placingBasisCode) || CoverHolderPlacingBases.includes(previous.fields.placingBasisCode));
      let kycDataPacket = getOrbisDataPacket(previous.fields, "assured", assured, updateAssuredDetails);

      let updatedState = {
        assured: assured ? selectedAssured : null,
        secondAssured: previous.fields.isDualStamp ? selectedAssured : null,
        kycDataPacket,
        hamiltonId: updateAssuredDetails ? assured?.hamiltonId : previous.fields.hamiltonId,
        address: updateAssuredDetails ? '' : previous.fields.address,
        postCode: updateAssuredDetails ? '' : previous.fields.postCode
      };

      if (previous.fields.typeOfReassured === TypesOfReassured.Individual) {
        updatedState.address = assured?.address;
        updatedState.postCode = assured?.postCode;
        updatedState.dateOfBirth = assured?.insuredDOB;
        updatedState.familyName = assured?.familyName;
        updatedState.givenName = assured?.givenName;
      } else {
        updatedState.dateOfBirth = null;
        updatedState.familyName = '';
        updatedState.givenName = '';
      }

      return {
        ...previous,
        fields: { ...previous.fields, ...updatedState }
      };
    });
  }, [setFormState, getOrbisDataPacket]);

  const onOrganisationChange = useCallback(async (org, changeOrgDetails, typeOfOrg) => {
    newAssuredValue.current = { ...org };
    setSanctionsDialogConfig({ changeOrgDetails, typeOfOrg });
    
    await checkForSanctionFlag(org, changeOrgDetails);
  }, [checkForSanctionFlag,setSanctionsDialogConfig]);
  
  const changeCompanyForSanctionScreeningDetails = useCallback(() => {
    const company = newAssuredValue?.current;
    const selectedCompany = {
      companyForSanctionsScreening: company?.assured ?? '',
      companyIdForSanctionsScreening: company?.assuredId,
      addressForSanctionsScreening: '',
      postCodeForSanctionsScreening: ''
    };

    setFormState(previous => {
      var updateAssuredDetails = !(ReassuredPlacingBases.includes(previous.fields.placingBasisCode) || CoverHolderPlacingBases.includes(previous.fields.placingBasisCode));
      let kycDataPacket = getOrbisDataPacket(previous.fields, "companyForSanctionsScreening", company, true);
      return {
        ...previous,
        fields: {
          ...previous.fields,
          companyForSanctionsScreening: company ? selectedCompany : null,
          kycDataPacket,
          companySanctionsHamiltonId: updateAssuredDetails ? company?.hamiltonId : previous.fields.companySanctionsHamiltonId,
        }
      };
    });
  }, [setFormState]);

  const changeReassuredDetails = useCallback(() => {
    let reassured = newAssuredValue?.current?.reassured ?? '';

    setFormState(previous => {
      let kycDataPacket = getOrbisDataPacket(previous.fields, "reassured", newAssuredValue?.current, true);

      return {
        ...previous,
        fields: {
          ...previous.fields,
          reassured,
          secondReassured: previous.fields.isDualStamp ? reassured : null,
          kycDataPacket,
          hamiltonId: newAssuredValue?.current?.hamiltonId,
          address: '',
          postCode: ''
        }
      };
    });
  }, [setFormState, getOrbisDataPacket]);

  const changeCoverholderDetails = useCallback(() => {
    let coverHolder = newAssuredValue?.current?.coverHolder ?? '';

    setFormState(previous => {
      let kycDataPacket = getOrbisDataPacket(previous.fields, "coverholder", newAssuredValue?.current, true);

      return {
        ...previous,
        fields: {
          ...previous.fields,
          coverHolder,
          kycDataPacket,
          hamiltonId: newAssuredValue?.current?.hamiltonId
        }
      };
    });
  }, [setFormState, getOrbisDataPacket]);

  const changeUpdatedAssuredDetails = useCallback(() => {
    let assured = newAssuredValue.current;
    setFormState(previous => {
      var isIndividual = previous.fieldsupdatedTypeOfReassured === TypesOfReassured.Individual;

      let orgType;
      let updatedAssuredName;
      if (fields.assuredType === OrganisationTypes.Assured.toUpperCase()) {
        orgType = OrganisationTypes.Assured;
        updatedAssuredName = SingleEntityFieldNames.updatedAssured;
      } else {
        orgType = OrganisationTypes.Reassured;
        updatedAssuredName = SingleEntityFieldNames.updatedReassured;
      }

      let secondKycDataPacket = getOrbisDataPacket(previous.fields, orgType, assured, true);

      return {
        ...previous,
        fields: {
          ...previous.fields,
          [updatedAssuredName]: assured?.[orgType] ?? null,
          secondKycDataPacket,
          secondHamiltonId: assured?.hamiltonId,
          updatedAddress: isIndividual ? assured?.address : '',
          updatedDateOfBirth: isIndividual ? assured?.insuredDOB : previous.fields.updatedDateOfBirth,
          updatedPostCode: isIndividual ? assured?.postCode : ''
        }
      };
    });
  }, [setFormState, fields]);

  const changeRenewalAssuredDetails = useCallback(() => {
    let assured = newAssuredValue.current;
    setFormState(previous => {
      var isIndividual = previous.fields.renewalTypeOfReassured === TypesOfReassured.Individual;
      let type;
      let renewalType;
      if (fields.assuredType === OrganisationTypes.Reassured.toUpperCase()) {
        renewalType = SingleEntityFieldNames.renewalReassured;
        type = OrganisationTypes.Reassured;
      } else if (fields.assuredType === OrganisationTypes.Coverholder.toUpperCase()) {
        renewalType = SingleEntityFieldNames.renewalCoverHolder;
        type = OrganisationTypes.Coverholder;
      } else {
        renewalType = SingleEntityFieldNames.renewalAssured;
        type = OrganisationTypes.Assured;
      }
      let kycDataPacket = getOrbisDataPacket(previous.fields, type, assured, true);
      
      return {
        ...previous,
        fields: {
          ...previous.fields,
          [renewalType]: assured?.[type] ?? null,
          kycDataPacket,
          hamiltonId: assured?.hamiltonId,
          renewalAddress: isIndividual ? assured?.address : '',
          renewalDateOfBirth: isIndividual ? assured?.insuredDOB : previous.fields.renewalDateOfBirth,
          renewalPostCode: isIndividual ? assured?.postCode : ''
        }
      };
    });
  }, [setFormState, fields]);

  const onAssuredPopupSubmit = useCallback((fieldNames, submitData, isUpdateRequired, isRenewalOrUpdate) => {
    let assured = {
      assured: submitData.data.assured,
      assuredId: submitData.data.assuredId
    };

    let address = submitData.data?.address;
    let postCode = submitData.data?.postCode;
    let assuredFieldName = fieldNames.type;

    setFormState(previous => {
      return {
        ...previous,
        fields: { 
          ...previous.fields, 
          [assuredFieldName]: !isRenewalOrUpdate ? assured : assured.assured, 
          [fieldNames.addressFieldName]: isUpdateRequired ? address : previous.fields.address, 
          [fieldNames.postCodeFieldName]: isUpdateRequired ? postCode : previous.fields.postCode,
          hamiltonId: isUpdateRequired ? null : previous.fields.hamiltonId,
        }
      }
    });

    setValidationValue(assuredFieldName, assured, { shouldValidate: true });
  }, [setFormState, setValidationValue]);

  const onIndividualPopupSubmit = useCallback((assuredType, submitData, isRenewal) => {
    let givenName = submitData.data.givenName ?? '';
    let familyName = submitData.data.familyName ?? '';
    let address = submitData.data?.address;
    let postCode = submitData.data?.postCode;
    let assured = submitData.data.assured;

    let givenNameField;
    let familyNameField;
    let addressField;
    let postCodeField;
    let hamiltonIdField;

    if (!isRenewal) {
      givenNameField = SingleEntityFieldNames.updatedGivenName;
      familyNameField = SingleEntityFieldNames.updatedFamilyName;
      addressField = SingleEntityFieldNames.updatedAddress;
      postCodeField = SingleEntityFieldNames.updatedPostCode;
      hamiltonIdField = DualEntityFieldNames.hamiltonId;
    }
    else {
      givenNameField = SingleEntityFieldNames.renewalGivenName;
      familyNameField = SingleEntityFieldNames.renewalFamilyName;
      addressField = SingleEntityFieldNames.renewalAddress;
      postCodeField = SingleEntityFieldNames.renewalPostCode;
      hamiltonIdField = SingleEntityFieldNames.hamiltonId;
    }

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        [assuredType]: assured,
        [givenNameField]: givenName,
        [familyNameField]: familyName,
        [addressField]: address,
        [postCodeField]: postCode,
        [hamiltonIdField]: null,
      }
    }));

    setValidationValue(assuredType, assured, { shouldValidate: true });
  }, [setFormState, setValidationValue]);

  const onCountryChange = useCallback(value => {
    let countryName = value?.shortName ?? '';
    let countryCode = value?.iso2 ?? '';

    resetFieldValidationState("_masterAssuredOrMasterReassured");
    resetFieldValidationState("_secondMasterAssuredOrMasterReassured");
    setValidationValue("assured", null);
    setValidationValue("secondAssured", null);
    setValidationValue("reassured", "");
    setValidationValue("coverHolder", "");
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setValidationValue("_secondMasterAssuredOrMasterReassured", "");

    setForceClearDatasourceState({
      "assured": true,
      "reassured": true,
      "coverHolder": true,
      "obligor": true,
      "_masterAssuredOrMasterReassured": true,
      "_secondMasterAssuredOrMasterReassured": true
    });

    setFormState(previous => {
      return {
        ...previous,
        fields: {
          ...previous.fields,
          countryName,
          countryCode,
          assured: null,
          reassured: '',
          policyId: null,
          policyReferenceNumber: '',
          secondAssured: null,
          secondReassured: '',
          secondPolicyId: null,
          secondPolicyReferenceNumber: '',
          masterPolicyReferenceNumber: '',
          masterPolicyId: null,
          masterAssured: '',
          masterReassured: '',
          _masterAssuredOrMasterReassured: '',
          _secondMasterAssuredOrMasterReassured: '',
          secondMasterAssured: '',
          secondMasterReassured: '',
          secondMasterPolicyReferenceNumber: '',
          secondMasterPolicyId: null,
          coverHolder: ''
        }
      };
    });

    abortController.abort();
  }, [setFormState, abortController]);

  const onUpdatedCountryChange = useCallback(value => {
    let updatedCountryName = value?.shortName ?? '';
    let updatedCountryCode = value?.iso2 ?? '';

    setValidationValue("updatedAssured", null);

    setForceClearDatasourceState({
      "updatedAssured": true
    });

    setFormState(previous => {
      return {
        ...previous,
        fields: {
          ...previous.fields,
          updatedCountryName,
          updatedCountryCode,
          updatedAssured: null
        }
      };
    });

    abortController.abort();
  }, [setFormState, setForceClearDatasourceState, setValidationValue, abortController]);

  const onRenewalCountryChange = useCallback(value => {
    let renewalCountryName = value?.shortName ?? '';
    let renewalCountryCode = value?.iso2 ?? '';
  
    setValidationValue("renewalAssured", null);
    setValidationValue("renewalReassured", null);
    setValidationValue("renewalCoverHolder", null);
  
    setForceClearDatasourceState({
      "renewalAssured": true,
      "renewalReassured": true,
      "renewalCoverHolder": true
    });
  
    setFormState(previous => {
      return {
        ...previous,
        fields: {
          ...previous.fields,
          renewalCountryName,
          renewalCountryCode,
          renewalAssured: null,
          renewalReassured: null,
          renewalCoverHolder: null
        }
      };
    });
  
    abortController.abort();
  }, [setFormState, setForceClearDatasourceState, setValidationValue, abortController]);  

  const onCountryForSanctionsScreeningChange = useCallback(value => {
    let countryForSanctionsScreening = value?.shortName ?? '';
    let countryCodeForSanctionsScreening = value?.iso2 ?? '';

    setValidationValue("companyForSanctionsScreening", null);

    setForceClearDatasourceState({
      "companyForSanctionsScreening": true
    });

    setFormState(previous => {
      return {
        ...previous,
        fields: {
          ...previous.fields,
          countryForSanctionsScreening,
          countryCodeForSanctionsScreening,
          companyForSanctionsScreening: null
        }
      };
    });

    abortController.abort();
  }, [setFormState, abortController, setForceClearDatasourceState, setValidationValue]);

  const onExpiringPolicyChange = useCallback(policy => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        policyId: policy?.policyId ?? '',
        expiringPolicyReferenceNumber: policy?.policyReferenceNumber ?? '',
        expiringProgrammeReference: policy?.programmeReference ?? '',
        placingBasis: policy?.placingBasis ?? '',
        placingBasisCode: policy?.placingType ?? '',
        declarationReference: policy?.declarationReference ?? '',
        umr: policy?.umr ?? '',
        reassuredChangedAtFirmOrder: false,
        typeOfReassured: '',
        updatedAssured: null,
        renewalAssured: null,
        renewalReassured: null,
        renewalCoverHolder: null,
        dateOfBirth: null,
        expiringOrganisationName: policy?.assured ?? policy?.assuredName ?? '',
        assuredType: policy?.assuredType ?? ''
      }
    }));

    setRenewalState({ ...initialRenewalState });
  }, [initialRenewalState, setFormState, setRenewalState]);

  const onDateOfBirthChange = useCallback((name, date) => {
    let dateOfBirth = validateDate(date) ? getFormattedDate(date) : null;

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        [name]: dateOfBirth ?? date,
      }
    }));
  }, [setFormState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getCompanyRequiredError = (name, labelName) => {
    return !!labelName && errors[name]?.type === 'required' &&
      <span role="alert">{labelName} is required</span>;
  }

  const getCountryRequiredError = (name, labelName) => {
    return !!labelName && errors[name]?.type === 'required' &&
      <span role="alert">{labelName} is required</span>;
  }

  const getAssuredRequiredError = (errorAlias, assuredType) => {
    if (errors[errorAlias]?.type === 'required') {
      return <span role="alert">"{GeneralDetailsFields[assuredType]}" is required</span>;
    }
  }

  const getPlacingBasisError = name => {
    return errors[name]?.type === 'validate' &&
      <span role="alert">Selected "{GeneralDetailsFields.documentType}" cannot be associated with selected "{GeneralDetailsFields[name]}"</span>;
  }

  const getDateValidationError = name => {
    let validDate = errors[name]?.type === 'validDate' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is not valid</span>;

    return validDate;
  }

  const getPolicyIsRenewedError = name => {
    return errors[name]?.type === 'validate' &&
      <span role="alert">"{GeneralDetailsFields.expiringPolicyReference}" has been renewed</span>;
  }

  const getProgrammeIsRenewedError = name => {
    return errors[name]?.type === 'validate' &&
      <span role="alert">All policy references in "{GeneralDetailsFields.expiringProgrammeReference}" have been renewed"</span>;
  }

  const renderSanctionWarning = () => {
    return (
      <SanctionWarning
        sanctionsDialogConfig={sanctionsDialogConfig}
        setSanctionsDialogConfig={setSanctionsDialogConfig}
        setValidationValue={setValidationValue}
        setForceClearDatasourceState={setForceClearDatasourceState}
        newAssuredValue={newAssuredValue}
        sanctionWarningDialogState={sanctionWarningDialogState}
        setSanctionWarningDialogState={setSanctionWarningDialogState}
        setLoading={setLoading}
      />
    );
  }

  const renderRenewedPolicyCheckbox = () => {
    const name = "renewedPolicy";

    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(_event, checked) => onRenewedPolicyChecked(checked)}
              checked={fields[name]}
              inputRef={ai?.fieldsRef?.[name]} 
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderHighPrioritySanctionsCheck = () => {
    const name = "highPrioritySanctionsCheck";
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              name={name}
              checked={fields.highPrioritySanctionsCheck}
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
            />
          }
          label={GeneralDetailsFields.highPrioritySanctionsCheck}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderSendTaskToOperations = (isVisible) => {
    const name = "sendTaskToOperations";

    return isVisible && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              name={name}
              edge="end"
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
              checked={fields[name]}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderUnderwriter = () => {
    return (
      <Grid item>
        <ComponentController
          name="underwriter"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={fields[name]}
              datasource={Datasource.underwritersData}
              requestData={{
                BusinessEntities: dropdownsData.entitiesForFilter
              }}
              abortController={null}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              onChange={value => { onUnderwriterChange(value); onChange(value); }}
              onBlur={onBlur}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderYearOfAccount = () => {
    return (
      <Grid item>
        <ComponentController
          name="yoa"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                disabled={dropdownsData.years.length === 0}
                value={fields.yoa}
                data={dropdownsData.years}
                onChange={e => { onYOAChange(e.target?.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                shouldSetDefaultValue={true}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderEntity = () => {
    let data = dropdownsData.eclipseEntities.filter(x => x.yoa === fields.yoa).map(x => ({ Key: x.entityCode, Value: x.entityDisplayName }));
    let selectedValue = data.find(x => x.Key === fields.businessEntity)?.Value ?? '';

    return (
      <Grid item>
        <ComponentController
          name="businessEntity"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                disabled={!fields.yoa || !dropdownsData.eclipseEntities || dropdownsData.eclipseEntities.length === 0}
                value={selectedValue}
                data={data.map(x => x.Value)}
                onChange={e => { onEntityChange(e); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                shouldSetDefaultValue={true}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderPlacingBasis = () => {
    // to be reverted: temp change according to the task below to avoid issue with Coverholders after Moody's release
    // Task 230150: Hide Placing Basis which are related to Coverholder for Submissions, Quote, Firm Order Only
    let availablePlacingBases = dropdownsData.placingBases.filter(p => !CoverHolderPlacingBases.includes(p.code)).map(p => p.name);

    return (
      <Grid item>
        <ComponentController
          name="placingBasis"
          control={control}
          required
          rules={{ validate: placingBasis => validatePlacingBasis(placingBasis, fields.documentType, dropdownsData.documentTypes, dropdownsData.placingBases) }}
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                value={fields[name]}
                data={availablePlacingBases}
                onChange={e => { onPlacingBasisChange(e); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name) || getPlacingBasisError(name)}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderBrokerCode = () => {
    const getBrokerInfo = (broker) => broker ? `${broker.brokerCode} - ${broker.brokerPseud} - ${broker.broker}` : null;

    return (
      <Grid item>
        <ComponentController
          name="broker"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={getBrokerInfo(fields[name])}
              forceClearDatasource={forceClearDatasourceState}
              datasource={Datasource.brokers}
              abortController={null}
              disabled={!fields.businessEntity}
              requestData={{
                syndicate: businessEntity.entityCode
              }}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              onChange={value => { onBrokerChange(value); onChange(value); }}
              onBlur={onBlur}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={4}><div>Broker Code</div></Grid>
                    <Grid item xs={4}><div>Broker Name</div></Grid>
                    <Grid item xs={4}><div>Broker Pseud</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={4}><div>{option.brokerCode}</div></Grid>
                    <Grid item xs={4}><div>{option.broker}</div></Grid>
                    <Grid item xs={4}><div>{option.brokerPseud}</div></Grid>
                  </Grid>
                );
              }}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderBrokerContact = () => {
    return (
      <Grid item>
        <ComponentController
          name="brokerContact"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={fields[name]}
              datasource={Datasource.brokerContacts}
              forceClearDatasource={forceClearDatasourceState}
              abortController={null}
              disabled={!fields.broker}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              config={{ autoSearch: true }}
              requestData={{
                brokerId: `${fields.broker?.brokerId}`
              }}
              setValidationValue={setValidationValue}
              setFormState={setFormState}
              setBannerState={setBannerState}
              onChange={value => { onFieldChange(name, value); onChange(value); }}
              Popup={BrokerContactPopup}
              onBlur={onBlur}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderOperatingTerritory = () => {
    const name = "operatingTerritory";

    return (
      <Grid item>
        <PickerComponent
          label={GeneralDetailsFields[name]}
          fieldName={name}
          value={fields[name]}
          datasource={Datasource[name]}
          abortController={null}
          setAbortController={setAbortController}
          setBannerState={setBannerState}
          onChange={value => { onOperatingTerritoryChange(value); }}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderDeclineImmediately = () => {
    const name = "declineImmediately";

    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              name={name}
              edge="end"
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
              checked={fields[name]}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderTypeOfReassured = (name, handleTypeOfReassuredChange) => {
    let organisationTypes = dropdownsData.organisationTypes.find(x => x.placingBasis === fields.placingBasisCode)?.organisations ?? [];
    let render = fields.placingBasisCode !== PlacingBases.Declaration;
    let disabled = !fields.placingBasis || organisationTypes.length === 0;

    return render && (
      <Grid item>
        <ComponentController
          name={name}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                disabled={disabled}
                label={GeneralDetailsFields[name]}
                value={fields[name]}
                data={organisationTypes}
                onChange={e => { handleTypeOfReassuredChange(e); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                shouldSetDefaultValue={true}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderDateOfBirth = name => {
    return (
      <Grid item>
        <ComponentController
          name={name}
          control={control}
          rules={
            {
              validate: { validDate: value => validateDate(value) }
            }}
          render={({ field: { name, onChange, onBlur } }) =>
            <DatePickerElement
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              format="dd/MM/yyyy"
              value={fields[name]}
              onChange={e => { onDateOfBirthChange(name, e); onChange(e); }}
              onBlur={onBlur}
              error={!!errors[name]}
              helperText={getDateValidationError(name)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderAddress = name => {
    return (
      <Grid item>
        <ComponentController
          name={name}
          control={control}
          rules={{ maxLength: maxAddressLength }}
          render={({ field: { name, onBlur, onChange } }) =>
            <TextField
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
              onBlur={onBlur}
              value={fields[name]}
              error={!!errors[name]}
              helperText={<InformationMessage
                fieldName={GeneralDetailsFields[name]}
                fieldErrors={errors[name]}
                maxLength={maxAddressLength}
                showLengthInfo={true}
                length={fields[name]?.length}
              />}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderPostCode = name => {
    return (
      <Grid item>
        <ComponentController
            name={name}
            control={control}
            rules={{ maxLength: maxPostCodeLength }}
            render={({ field: { name, onBlur, onChange } }) =>
              <TextField
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                fullWidth
                onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
                onBlur={onBlur}
                value={fields[name]}
              />
            }
          />
      </Grid>
    );
  }

  const renderCountry = (name, typeOfReassuredName, handleCountryChange, isUpdated, isRenewal) => {
    let typeOfReassuredVal = fields[typeOfReassuredName];
    let labelName;

    if (isUpdated) {
      let fieldName = typeOfReassuredVal === TypesOfReassured.Company ? 'updatedCountryOfIncorporation' :
        typeOfReassuredVal === TypesOfReassured.Individual ? 'updatedCountryOfResidency' :
          'updatedCountryOfCover';

      labelName = GeneralDetailsFields.updatedCountry[fieldName];
    }
    else if (isRenewal){
      let fieldName = typeOfReassuredVal === TypesOfReassured.Company ? 'renewalCountryOfIncorporation' :
        typeOfReassuredVal === TypesOfReassured.Individual ? 'renewalCountryOfResidency' :
          'renewalCountryOfCover';

      labelName = GeneralDetailsFields.renewalCountry[fieldName];
    }
    else {
      let fieldName = typeOfReassuredVal === TypesOfReassured.Company ? 'countryOfIncorporation' :
        typeOfReassuredVal === TypesOfReassured.Individual ? 'countryOfResidency' :
          'countryOfCover';

      labelName = GeneralDetailsFields.country[fieldName];
    }

    return typeOfReassuredVal && (
      <Grid item>
        <ComponentController
          name={name}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={labelName}
              fieldName={name}
              value={fields[name]}
              config={{ showAddOptionIfNoData: false }}
              datasource={Datasource.countries}
              forceClearDatasource={forceClearDatasourceState}
              abortController={abortController}
              error={!!errors[name]}
              errorText={getCountryRequiredError(name, labelName)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              setAbortController={setAbortController}
              onChange={value => { handleCountryChange(value); onChange(value); }}
              onBlur={onBlur}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={9}><div>Country</div></Grid>
                    <Grid item xs={3}><div>Country Code</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={9}><div>{option.shortName}</div></Grid>
                    <Grid item xs={3}><div>{option.iso2}</div></Grid>
                  </Grid>
                );
              }}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderAssuredEclipseLookup = () => {
    let assuredType = "assuredOrMasterAssured";
    let render = ReassuredPlacingBases.includes(fields.placingBasisCode);

    return render && (
      <Grid item>
        <ComponentController
          name="assured"
          control={control}
          required = {false}
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[assuredType]}
              fieldName={name}
              value={fields.assured}
              datasource={Datasource.assuredNames}
              abortController={abortController}
              error={!!errors[name]}
              errorText={getAssuredRequiredError(name, assuredType)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              setAbortController={setAbortController}
              onChange={value => { onAssuredEclipseLookupChange(value); onChange(value); }}
              onBlur={onBlur}
              Popup={AssuredEclipsePopup}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={8}><div>Assured</div></Grid>
                    <Grid item xs={4}><div>Country</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={8}><div>{option.assured}</div></Grid>
                    <Grid item xs={4}><div>{option.country}</div></Grid>
                  </Grid>
                );
              }}
            />
          }
        />
      </Grid>
    );
  }

  const onAssuredEclipseLookupChange = useCallback(assured => {
    const selectedAssured = {
      assured: assured?.assured ?? '',
      assuredId: assured?.assuredId,
      effectiveFromDate: setZeroTime(assured?.effectiveFromDate),
      effectiveToDate: setZeroTime(assured?.effectiveToDate)
    };

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        assured: assured ? selectedAssured : null,
        secondAssured: previous.fields.isDualStamp ? selectedAssured : null
      }
    }));
  }, [setFormState]);

  const renderAssured = () => {
    let assuredType = "assuredOrMasterAssured";
    let datasource = !fields.typeOfReassured ? Datasource.assuredNames : Datasource.organisations.assured;
    let render = fields.placingBasisCode !== PlacingBases.Declaration;
    let disabled = !fields.typeOfReassured || !fields.countryName;
    let requiredField = ReassuredPlacingBases.includes(fields.placingBasisCode) || CoverHolderPlacingBases.includes(fields.placingBasisCode);

    const popupConfig = {
      type: "assured",
      addressFieldName: SingleEntityFieldNames.address,
      postCodeFieldName: SingleEntityFieldNames.postCode
    };

    let popup = fields.typeOfReassured === TypesOfReassured.Individual ? IndividualAssuredPopup : AssuredPopup;
    let onPopupSubmit = fields.typeOfReassured === TypesOfReassured.Individual ? null : submitData => onAssuredPopupSubmit(popupConfig, submitData, !requiredField, false);

    return (render && !requiredField) && (
      <Grid item>
        <ComponentController
          name="assured"
          control={control}
          required={!requiredField}
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[assuredType]}
              fieldName={name}
              value={fields[name]}
              datasource={datasource}
              config={{
                newValueAtTheEnd: true,
                rowsLimit: 100,
                pickerViewClass: "assured-picker-view"
              }}
              requestData={{
                isUpdateRequired: !requiredField,
                typeOfReassured: fields.typeOfReassured,
                countryCode: fields.countryCode,
                entityDOB: fields.dateOfBirth ?? null,
                address: fields.address,
                postCode: fields.postCode
              }}
              forceClearDatasource={forceClearDatasourceState}
              disabled={disabled}
              abortController={abortController}
              error={!!errors[name]}
              errorText={getAssuredRequiredError(name, assuredType)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              setAbortController={setAbortController}
              onChange={value => { onOrganisationChange(value, changeAssuredDetails,'assured'); onChange(value); }}
              onBlur={onBlur}
              onCustomDataLoad={onOrganisationsDataLoad}
              datasourceProperty="organisations"
              Popup={popup}
              onPopupSubmit={onPopupSubmit}
              OptionsHeader={renderAssuredLookupHeader(OrganisationTypes.Assured, fields.typeOfReassured)}
              OptionView={renderAssuredLookupView(OrganisationTypes.Assured, fields.typeOfReassured)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderCountryOfIncorporation = () => {
    let name = 'countryForSanctionsScreening';
    let labelName = GeneralDetailsFields.sanctionsScreeningCountry[name];
    let render = fields.typeOfReassured == TypesOfReassured.Other
    return render && (
      <Grid item container justifyContent='flex-end'>
        <Grid item xs={10}>
          <ComponentController
            name={name}
            control={control}
            render={({ field: { name, onBlur, onChange } }) =>
              <PickerComponent
                label={labelName}
                fieldName={name}
                value={fields.countryForSanctionsScreening}
                config={{ showAddOptionIfNoData: false }}
                datasource={Datasource.countries}
                forceClearDatasource={forceClearDatasourceState}
                abortController={abortController}
                setBannerState={setBannerState}
                setValidationValue={setValidationValue}
                setLoading={setLoading}
                setFormState={setFormState}
                setAbortController={setAbortController}
                onChange={value => { onCountryForSanctionsScreeningChange(value); onChange(value); }}
                onBlur={onBlur}
                OptionsHeader={({ className }) => {
                  return (
                    <Grid container direction="row" spacing={0} className={className}>
                      <Grid item xs={9}><div>Country</div></Grid>
                      <Grid item xs={3}><div>Country Code</div></Grid>
                    </Grid>
                  );
                }}
                OptionView={({ option, className }) => {
                  return (
                    <Grid container direction="row" spacing={0} className={className}>
                      <Grid item xs={9}><div>{option.shortName}</div></Grid>
                      <Grid item xs={3}><div>{option.iso2}</div></Grid>
                    </Grid>
                  );
                }}
              />
            }
          />
        </Grid>
      </Grid>
    );
  }
  
  const renderCompanyForSanctionsScreening = () => {
    let name = 'companyForSanctionsScreening';
    let labelName = GeneralDetailsFields.sanctionsScreeningCompany[name];
    let datasource = Datasource.organisations.assured;
    let render = fields.typeOfReassured == TypesOfReassured.Other
    let isCountryOfIncorporationSelected = fields.countryForSanctionsScreening;
     
    return render && (
      <Grid item container justifyContent='flex-end'>
        <Grid item xs={10}>
          <ComponentController
            name={name}
            control={control}
            required={isCountryOfIncorporationSelected}
            render={({ field: { name, onBlur, onChange } }) =>
              <PickerComponent
                label={labelName}
                fieldName={name}
                value={fields.companyForSanctionsScreening}
                datasource={datasource}
                config={{
                  newValueAtTheEnd: true,
                  rowsLimit: 100,
                  pickerViewClass: "assured-picker-view"
                }}
                requestData={{
                  typeOfReassured: TypesOfReassured.Company,
                  countryCode: fields.countryCodeForSanctionsScreening
                }} 
                forceClearDatasource={forceClearDatasourceState}
                disabled={!isCountryOfIncorporationSelected}
                abortController={abortController}
                error={isCountryOfIncorporationSelected && !!errors[name]}
                errorText={isCountryOfIncorporationSelected && getCompanyRequiredError(name, labelName)}
                setBannerState={setBannerState}
                setValidationValue={setValidationValue}
                setLoading={setLoading}
                setFormState={setFormState}
                setAbortController={setAbortController}
                onChange={value => { onOrganisationChange(value, changeCompanyForSanctionScreeningDetails, 'companyForSanctionsScreening'); onChange(value); }}
                onBlur={onBlur}
                datasourceProperty="organisations"
                Popup={CompanyPopup}
                onCustomDataLoad={onOrganisationsDataLoad}
                OptionsHeader={renderAssuredLookupHeader(OrganisationTypes.Assured, fields.typeOfReassured)}
                OptionView={renderAssuredLookupView(OrganisationTypes.Assured, fields.typeOfReassured)}
              />
            }
          />
        </Grid>
      </Grid>
    );
  }

  const renderReassured = () => {
    let render = ReassuredPlacingBases.includes(fields.placingBasisCode);
    let disabled = !fields.typeOfReassured || !fields.countryName;
    let datasource = !fields.typeOfReassured ? Datasource.reassured : Datasource.organisations.reassured;

    return render && (
      <Grid item>
        <ComponentController
          name="reassured"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={fields[name] ?? ''}
              abortController={abortController}
              datasource={datasource}
              config={{
                newValueAtTheEnd: true,
                rowsLimit: 100,
                pickerViewClass: "assured-picker-view"
              }}
              requestData={{
                typeOfReassured: fields.typeOfReassured,
                countryCode: fields.countryCode,
                entityDOB: fields.dateOfBirth,
                address: fields.address
              }}
              forceClearDatasource={forceClearDatasourceState}
              disabled={disabled}
              setAbortController={setAbortController}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              onChange={value => { onOrganisationChange(value, changeReassuredDetails, 'reassured'); onChange(value); }}
              onBlur={onBlur}
              onCustomDataLoad={onOrganisationsDataLoad}
              datasourceProperty="organisations"
              Popup={ReassuredPopup}
              OptionsHeader={renderAssuredLookupHeader(OrganisationTypes.Reassured, fields.typeOfReassured)}
              OptionView={renderAssuredLookupView(OrganisationTypes.Reassured, fields.typeOfReassured)}              
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderCoverHolder = () => {
    let render = CoverHolderPlacingBases.includes(fields.placingBasisCode);
    let disabled = !fields.typeOfReassured || !fields.countryName;
    let datasource = !fields.typeOfReassured ? Datasource.coverHolder : Datasource.organisations.coverholder;

    return render && (
      <Grid item>
        <ComponentController
          name="coverHolder"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={fields[name]}
              datasource={datasource}
              config={{
                rowsLimit: 100,
                pickerViewClass: "assured-picker-view"
              }}
              requestData={{
                typeOfReassured: fields.typeOfReassured,
                countryCode: fields.countryCode,
                entityDOB: fields.dateOfBirth,
                address: fields.address
              }}
              forceClearDatasource={forceClearDatasourceState}
              disabled={disabled}
              abortController={null}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              onChange={value => { onOrganisationChange(value, changeCoverholderDetails,'coverHolder'); onChange(value); }}
              onBlur={onBlur}
              onCustomDataLoad={onOrganisationsDataLoad}
              datasourceProperty="organisations"
              OptionsHeader={renderAssuredLookupHeader(OrganisationTypes.Coverholder, fields.typeOfReassured)}
              OptionView={renderAssuredLookupView(OrganisationTypes.Coverholder, fields.typeOfReassured)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderEplacement = () => {
    const name = "eplacement";

    return (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="placementMethod"
            label={GeneralDetailsFields[name]}
            value={fields[name]}
            data={dropdownsData.eplacements}
            onChange={e => onFieldChange(name, e.target.value)}
            inputRef={ai?.fieldsRef?.[name]}
          />
        </FormControl>
      </Grid>
    );
  }

  const renderTypeOfModellingRisk = () => {
    const name = "typeOfModellingRisk";

    return (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id={name}
            name={name}
            label={GeneralDetailsFields[name]}
            value={fields[name]}
            emptyValue={true}
            data={dropdownsData.typesOfModellingRisk}
            onChange={e => onFieldChange(name, e.target?.value)}
            inputRef={ai?.fieldsRef?.[name]}
          />
        </FormControl>
      </Grid>
    );
  }

  const renderInceptionDate = () => {
    const validateYear = date => {
      if (!date || !fields.yoa) {
        return true;
      }

      return date.getFullYear() === fields.yoa;
    }

    return (
      <Grid item>
        <ComponentController
          name="inceptionDate"
          control={control}
          rules={
            {
              validate: { validDate: value => validateDate(value) && validateYear(value) }
            }}
          render={({ field: { name, onChange, onBlur } }) =>
            <DatePickerElement
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              format="dd/MM/yyyy"
              value={fields[name]}
              onChange={e => { onFieldChange(name, e); onChange(e); }}
              onBlur={onBlur}
              error={!!errors[name]}
              helperText={getDateValidationError(name)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderConditionCode = () => {
    let render = fields.documentType === DocumentTypes.FirmOrderOnly;

    return render && (
      <ConditionCode
        name={DefaultConditionName}
        dropdownsData={dropdownsData}
        fields={fields}
        control={control}
        errors={errors}
        trigger={trigger}
        setFormState={setFormState}
        setBannerState={setBannerState}
        validationRef={validationRef}
        isRequired={true}
        inputRef={ai?.fieldsRef?.[DefaultConditionName]}
      />
    );
  }

  const renderPricingNotRequired = () => {
    let render = fields.documentType === DocumentTypes.FirmOrderOnly;
    const name = "pricingNotRequired";

    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              name={name}
              onChange={(e, checked) => onFieldChange(name, checked)}
              checked={fields[name]}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderEeaRisk = () => {
    const name = "eeaRisk";

    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              name={name}
              onChange={(e, checked) => onFieldChange(name, checked)}
              checked={fields[name]}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const onExpiringReferenceSwitch = useCallback(checked => {
    setValidationValue("expiringPolicyReferenceNumber", "");
    setValidationValue("expiringProgrammeReference", "");
    setValidationValue("expiringOrganisationName", "");
    setValidationValue("renewalAssured", null);
    setValidationValue("renewalReassured", null);
    setValidationValue("renewalCoverHolder", null);
    setValidationValue("renewalCountryName", null);

    setForceClearDatasourceState({
      "renewalAssured": true,
      "renewalReassured": true,
      "renewalCoverHolder": true,
      "renewalCountryName": true
    });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        expiringReferenceSwitch: checked,
        expiringPolicyReferenceNumber: '',
        expiringProgrammeReference: '',
        expiringOrganisationName: '',
        renewalTypeOfReassured: '',
        renewalAssured: null,
        renewalReassured: null,
        renewalCoverHolder: null,
        renewalDateOfBirth: null,
        renewalAddress: '',
        renewalCountryName: '',
        renewalCountryCode: '',
        renewalPostCode: ''
      }
    }));
  }, [setFormState, setValidationValue]);

  const renderExpiringReferenceSwitch = () => {
    const name = "expiringReferenceSwitch";

    return (
      <Grid item>
        <FormControlWrapper
          control={
            <SwitchComponent
              name={name}
              checked={fields[name]}
              onChange={e => onExpiringReferenceSwitch(e.target?.checked)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderExpiringPolicyReference = () => {
    let render = !fields.expiringReferenceSwitch;

    return render && <>
      <Grid item>
        <ComponentController
          name="expiringPolicyReferenceNumber"
          control={control}
          required
          rules={{ validate: () => !fields.isExpiringPolicyRenewed }}
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields.expiringPolicyReference}
              fieldName="policyReferenceNumber"
              value={fields[name]}
              datasource={Datasource.expiringPolicies}
              requestData={{
                BusinessEntities: dropdownsData.entitiesForFilter
              }}
              error={!!errors[name]}
              errorText={getRequiredError(name) || getPolicyIsRenewedError(name)}
              config={{ pickerViewClass: "assured-picker-view" }}
              setAbortController={setAbortController}
              setBannerState={setBannerState}
              setLoading={setLoading}
              setFormState={setFormState}
              setValidationValue={setValidationValue}
              onChange={value => { onExpiringPolicyChange(value); onChange(value); }}
              onBlur={onBlur}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={2}><div>Organisation Name</div></Grid>
                    <Grid item xs={1}><div>Organisation Type</div></Grid>
                    <Grid item xs={1}><div>Entity</div></Grid>
                    <Grid item xs={1}><div>Major Class</div></Grid>
                    <Grid item xs={1}><div>YOA</div></Grid>
                    <Grid item xs={1}><div>Policy Reference</div></Grid>
                    <Grid item xs={1}><div>Policy Status</div></Grid>
                    <Grid item xs={1}><div>Country</div></Grid>
                    <Grid item xs={1}><div>UMR</div></Grid>
                    <Grid item xs={1}><div>Dec Ref</div></Grid>
                    <Grid item xs={1}><div>Placing Basis</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={2}><div>{option.assured}</div></Grid>
                    <Grid item xs={1}><div>{option.assuredType}</div></Grid>
                    <Grid item xs={1}><div>{option.entity}</div></Grid>
                    <Grid item xs={1}><div>{option.majorClassCode}</div></Grid>
                    <Grid item xs={1}><div>{option.yearOfAccount}</div></Grid>
                    <Grid item xs={1}><div>{option.policyReferenceNumber}</div></Grid>
                    <Grid item xs={1}><div>{option.policyStatus}</div></Grid>
                    <Grid item xs={1}><div>{option.country}</div></Grid>
                    <Grid item xs={1}><div>{option.umr}</div></Grid>
                    <Grid item xs={1}><div>{option.declarationReference}</div></Grid>
                    <Grid item xs={1}><div>{option.placingBasis}</div></Grid>
                  </Grid>
                );
              }}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    </>;
  }

  const renderExpiringProgrammeReference = () => {
    let render = fields.expiringReferenceSwitch;
    
    return render && (
      <Grid item>
        <ComponentController
          name="expiringProgrammeReference"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName="programmeReference"
              value={fields[name]}
              datasource={Datasource.programmes}
              requestData={{
                BusinessEntities: dropdownsData.entitiesForFilter
              }}
              error={!!errors[name]}
              errorText={getRequiredError(name) || getProgrammeIsRenewedError(name)}
              setAbortController={setAbortController}
              setBannerState={setBannerState}
              onChange={value => { onExpiringPolicyChange(value); onChange(value); }}
              onBlur={onBlur}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderChangeOnFirmOrderStage = () => {
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              name="reassuredChangedAtFirmOrder"
              edge="end"
              disabled={!fields.policyId}
              onChange={(_, checked) => onFirmOrderStageChange(checked)}
              checked={fields.reassuredChangedAtFirmOrder}
            />
          }
          label={GeneralDetailsFields.changeOnFirmOrderStage}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderUpdatedAssured = () => {
    let fieldName = "updatedAssured";
    let assuredType;
    let datasource;
    let viewLabel;

    if (fields.assuredType === OrganisationTypes.Assured.toUpperCase()) {
      assuredType = SingleEntityFieldNames.updatedAssured;
      datasource = Datasource.organisations.assured;
      viewLabel = OrganisationTypes.Assured;
    } else {
      assuredType = SingleEntityFieldNames.updatedReassured;
      datasource = Datasource.organisations.reassured;
      viewLabel = OrganisationTypes.Reassured;
    }

    let disabled = !fields.updatedTypeOfReassured || !fields.updatedCountryName;

    let popup;
    let onPopupSubmit;

    const popupConfig = {
      type: assuredType,
      addressFieldName: SingleEntityFieldNames.updatedAddress,
      postCodeFieldName: SingleEntityFieldNames.updatedPostCode
    };

    if (fields.updatedTypeOfReassured !== TypesOfReassured.Individual) {
      popup = AssuredPopup;
      onPopupSubmit = submitData => onAssuredPopupSubmit(popupConfig, submitData, true, true);
    } else {
      popup = IndividualAssuredPopup;
      onPopupSubmit = submitData => onIndividualPopupSubmit(assuredType, submitData, false);
    }

    return (
      <Grid item>
        <ComponentController
          name={assuredType}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[fieldName]}
              fieldName={name}
              value={fields[name]}
              datasource={datasource}
              config={{
                newValueAtTheEnd: true,
                rowsLimit: 100,
                pickerViewClass: "assured-picker-view"
              }}
              requestData={{
                typeOfReassured: fields.updatedTypeOfReassured,
                countryCode: fields.updatedCountryCode,
                entityDOB: fields.updatedDateOfBirth ?? null,
                address: fields.updatedAddress,
                postCode: fields.updatedPostCode
              }}
              datasourceProperty="organisations"
              forceClearDatasource={forceClearDatasourceState}
              disabled={disabled}
              abortController={abortController}
              error={!!errors[name]}
              errorText={getAssuredRequiredError(name, fieldName)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              setAbortController={setAbortController}
              onChange={value => { onOrganisationChange(value, changeUpdatedAssuredDetails, assuredType); onChange(value); }}
              onBlur={onBlur}
              onCustomDataLoad={onOrganisationsDataLoad}
              Popup={popup}
              onPopupSubmit={onPopupSubmit}
              OptionsHeader={renderAssuredLookupHeader(viewLabel, fields.typeOfReassured)}
              OptionView={renderAssuredLookupView(viewLabel, fields.typeOfReassured)}
            />
          }
        />
      </Grid>
    );
  }

  const renderRenewalAssured = () => {
    let fieldName = "renewAssuredMasterAssured";
    let assuredType;
    let datasource;
    let renewalType;
    if (fields.assuredType === OrganisationTypes.Reassured.toUpperCase()) {
      assuredType = OrganisationTypes.Reassured;
      datasource = !fields.renewalTypeOfReassured ? Datasource.reassured : Datasource.organisations.reassured;
      renewalType = SingleEntityFieldNames.renewalReassured;
    } else if (fields.assuredType === OrganisationTypes.Coverholder.toUpperCase()) {
      assuredType = OrganisationTypes.Coverholder;
      datasource = !fields.renewalTypeOfReassured ? Datasource.coverHolder : Datasource.organisations.coverholder;
      renewalType = SingleEntityFieldNames.renewalCoverHolder;
    } else {
      assuredType = OrganisationTypes.Assured;
      datasource = !fields.renewalTypeOfReassured ? Datasource.assuredNames : Datasource.organisations.assured;
      renewalType = SingleEntityFieldNames.renewalAssured;
    }

    let disabled = !fields.renewalTypeOfReassured || !fields.renewalCountryName;

    let popup;
    let onPopupSubmit;

    const popupConfig = {
      type: renewalType,
      addressFieldName: SingleEntityFieldNames.renewalAddress,
      postCodeFieldName: SingleEntityFieldNames.renewalPostCode
    };

    if (fields.renewalTypeOfReassured !== TypesOfReassured.Individual) {
      popup = AssuredPopup;
      onPopupSubmit = submitData => onAssuredPopupSubmit(popupConfig, submitData, true, true);
    } else {
      popup = IndividualAssuredPopup;
      onPopupSubmit = submitData => onIndividualPopupSubmit(renewalType, submitData, true);
    }

    return (
      <Grid item>
        <ComponentController
          name={renewalType}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[fieldName]}
              fieldName={name}
              value={fields[name]}
              datasource={datasource}
              config={{
                newValueAtTheEnd: true,
                rowsLimit: 100,
                pickerViewClass: "assured-picker-view"
              }}
              requestData={{
                typeOfReassured: fields.renewalTypeOfReassured,
                countryCode: fields.renewalCountryCode,
                entityDOB: fields.renewalDateOfBirth ?? null,
                address: fields.renewalAaddress,
                postCode: fields.renewalPostCode
              }}
              datasourceProperty="organisations"
              forceClearDatasource={forceClearDatasourceState}
              disabled={disabled}
              abortController={abortController}
              error={!!errors[name]}
              errorText={getAssuredRequiredError(name, fieldName)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              setAbortController={setAbortController}
              onChange={value => { onOrganisationChange(value, changeRenewalAssuredDetails, renewalType); onChange(value); }}
              onBlur={onBlur}
              onCustomDataLoad={onOrganisationsDataLoad}
              Popup={popup}
              onPopupSubmit={onPopupSubmit}
              OptionsHeader={renderAssuredLookupHeader(assuredType, fields.renewalTypeOfReassured)}
              OptionView={renderAssuredLookupView(assuredType, fields.renewalTypeOfReassured)}
            />
          }
        />
      </Grid>
    );
  }

  const renderMoodysLookupSection = () => {
    let render = fields.placingBasisCode !== PlacingBases.Declaration && fields.reassuredChangedAtFirmOrder;

    return render && <>
      {renderTypeOfReassured("updatedTypeOfReassured", onUpdatedTypeOfReassuredChange)}
      {renderUpdatedIndividualFiltersSection()}
      {renderCountry("updatedCountryName", "updatedTypeOfReassured", onUpdatedCountryChange, true)}
      {renderUpdatedAssured()}
    </>;
  }

  const renderRenewalSection = () => {
    let render = fields.placingBasisCode !== PlacingBases.Declaration

    return render && <>
      {renderExpiringOrganisationName()}
      {renderTypeOfReassured("renewalTypeOfReassured", onRenewalTypeOfReassuredChange)}
      {renderRenewalIndividualFiltersSection()}
      {renderCountry("renewalCountryName", "renewalTypeOfReassured", onRenewalCountryChange, false, true)}
      {renderRenewalAssured()}
      {renderCountryOfIncorporation()}
      {renderCompanyForSanctionsScreening()}
    </>;
  }

  const renderLookupForMasterAssuredAndPolicyReference = (
    lookupType = "lookupForMasterAssuredAndMasterPolicyReference"
  ) => {
    let entityFilter = isDualStampEntity(fields.businessEntity) ? [S4000Entity.DisplayValue] : [fields.businessEntity];

    return (<MasterPolicyReferenceControl
      entities={entityFilter}
      fields={fields}
      control={control}
      errors={errors}
      lookupType={lookupType}
      forceClearDatasourceState={forceClearDatasourceState}
      disabled={!fields.underwriter || !fields.yoa || !fields.businessEntity}
      setBannerState={setBannerState}
      setLoading={setLoading}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      abortController={abortController}
      setAbortController={setAbortController}
      inputRef={ai?.fieldsRef?.[DefaultMasterPolicyReferenceControlName]}
    />);
  }

  const renderMasterPolicyReferenceNumber = (name = "masterPolicyReferenceNumber") => {
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          label={GeneralDetailsFields[name]}
          fullWidth
          disabled
          value={fields.masterPolicyReferenceNumber ?? ""}
        />
      </Grid>
    );
  }

  const renderMasterAssured = (name = "_masterAssuredOrMasterReassured") => {
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields._masterAssuredOrMasterReassured ?? ""}
        />
      </Grid>
    );
  }

  const renderSecondLookupForMasterAssuredAndPolicyReference = (
    lookupType = "secondLookupForMasterAssuredAndMasterPolicyReference"
  ) => {
    return (<SecondMasterPolicyReferenceControl
      entities={["HDAC"]}
      fields={fields}
      control={control}
      errors={errors}
      lookupType={lookupType}
      forceClearDatasourceState={forceClearDatasourceState}
      disabled={!fields.underwriter || !fields.yoa || !fields.businessEntity}
      setBannerState={setBannerState}
      setLoading={setLoading}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      inputRef={ai?.fieldsRef?.[DefaultSecondMasterPolicyReferenceControlName]}
    />);
  }

  const renderSecondMasterPolicyReferenceNumber = (name = "secondMasterPolicyReferenceNumber") => {
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          label={GeneralDetailsFields[name]}
          fullWidth
          disabled
          value={fields[name] ?? ""}
        />
      </Grid>
    );
  }

  const renderExpiringOrganisationName = (name = "expiringOrganisationName") => {
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          label={GeneralDetailsFields[name]}
          fullWidth
          disabled
          value={fields[name] ?? ""}
        />
      </Grid>
    );
  }  

  const renderSecondMasterAssured = (name = "_secondMasterAssuredOrMasterReassured") => {
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields[name] ?? ""}
        />
      </Grid>
    );
  }

  const renderBrokerSection = () => {
    let render = fields.placingBasisCode !== PlacingBases.Declaration;

    return render && <>
      {renderBrokerCode()}
      {renderBrokerContact()}
    </>;
  }

  const renderIndividualFiltersSection = () => {
    let render = fields.typeOfReassured === TypesOfReassured.Individual;

    return render && <>
      {renderDateOfBirth("dateOfBirth")}
      {renderAddress("address")}
      {renderPostCode("postCode")}
    </>;
  }

  const renderUpdatedIndividualFiltersSection = () => {
    let render = fields.updatedTypeOfReassured === TypesOfReassured.Individual;

    return render && <>
      {renderDateOfBirth("updatedDateOfBirth")}
      {renderAddress("updatedAddress")}
      {renderPostCode("updatedPostCode")}
    </>;
  }

  const renderRenewalIndividualFiltersSection = () => {
    let render = fields.renewalTypeOfReassured === TypesOfReassured.Individual;

    return render && <>
      {renderDateOfBirth("renewalDateOfBirth")}
      {renderAddress("renewalAddress")}
      {renderPostCode("renewalPostCode")}
    </>;
  }

  const renderMasterPolicySection = () => {
    if (fields.placingBasisCode !== PlacingBases.Declaration) {
      return false;
    }

    if (!isDualStampEntity(fields.businessEntity)) {
      return <>
        {renderLookupForMasterAssuredAndPolicyReference()}
        {renderMasterPolicyReferenceNumber()}
        {renderMasterAssured()}
      </>;
    }

    return <>
      {renderLookupForMasterAssuredAndPolicyReference("lookupForMasterAssuredAndMasterPolicyReference4000")}
      {renderMasterPolicyReferenceNumber("masterPolicyReferenceNumber4000")}
      {renderMasterAssured("_masterAssuredOrMasterReassured4000")}
      {renderSecondLookupForMasterAssuredAndPolicyReference()}
      {renderSecondMasterPolicyReferenceNumber()}
      {renderSecondMasterAssured()}
    </>;
  }

  const renderSubmissionPolicySection = () => {
    const render = !fields.renewedPolicy;
    return render && <>
      {renderSendTaskToOperations(false)}
      {renderHighPrioritySanctionsCheck()}
      {renderUnderwriter()}
      {renderYearOfAccount()}
      {renderEntity()}
      {renderPlacingBasis()}
      {renderBrokerSection()}
      {renderOperatingTerritory()}
      {renderDeclineImmediately()}
      {renderTypeOfReassured("typeOfReassured", onTypeOfReassuredChange)}
      {renderIndividualFiltersSection()}
      {renderCountry("countryName", "typeOfReassured", onCountryChange)}
      {renderAssured()}
      {renderCountryOfIncorporation()}
      {renderCompanyForSanctionsScreening()}
      {renderMasterPolicySection()}
      {renderReassured()}
      {renderAssuredEclipseLookup()}
      {renderCoverHolder()}
      {renderEplacement()}
      {renderTypeOfModellingRisk()}
      {renderInceptionDate()}
      {renderConditionCode()}
      {renderPricingNotRequired()}
      {renderEeaRisk()}
      {renderSanctionWarning()}
    </>;
  }

  const renderRenewedPolicySection = () => {
    const render = fields.renewedPolicy;
    return render && <>
      {renderExpiringReferenceSwitch()}
      {renderExpiringPolicyReference()}
      {renderExpiringProgrammeReference()}
      {renderRenewalSection()}
      {renderChangeOnFirmOrderStage()}
      {renderMoodysLookupSection()}
      {renderEplacement()}
      {renderTypeOfModellingRisk()}
      {renderEeaRisk()}
      {renderSanctionWarning()}
    </>;
  }

  return <>
    {renderRenewedPolicyCheckbox()}
    {renderRenewedPolicySection()}
    {renderSubmissionPolicySection()}
  </>;
}

export default SubmissionSection;
